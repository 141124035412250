import { createStore } from "vuex";
import api from "../components/http/api";
import msg from "../components/http/msgbox";
import { dist2 } from "@/components/Game/GameList";
export default createStore({
  state: {
    isLoading: false,
    pop: {
      isLoading: false,
      data: {
        action: "",
        msg: "",
        class: "",
        type: "",
      },
    },
    parameter: {
      action: "",
      body: {},
    },
    list: [],
    flag_nav: true,
    all_game: {
      slot: ["migame"],
    },
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
    Pop(state, payload) {
      state.pop = payload;
    },
    GetIP() {
      api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    GetList(state, payload) {
      state.list = payload;
    },
    flagnav(state, payload) {
      state.flag_nav = payload;
    },
  },
  actions: {
    setGetIP(context) {
      context.commit("GetIP");
    },
    setLoading(context, payload) {
      context.commit("Loading", payload);
    },
    showPop(context, payload) {
      context.commit("Pop", payload);
    },
    getpost(context, parameter) {
      return new Promise((resolve) => {
        api.apiPost(parameter.action, parameter.body).then((res) => {
          if (msg.resPonseStatus(res)) {
            if (res.status == "fail") {
              msg.API_popmsg(res.msg, "", "error");
            } else {
              resolve(res);
            }
          }
        });
      });
    },
    getGameList(context, parameter) {
      parameter.body.gametype = parameter.body.gametype.toLowerCase();
      parameter.body.type = parameter.body.type.toLowerCase();
      if (parameter.action != "") {
        if (
          localStorage.getItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
          ) == null ||
          parameter.body.gametype == "guess"
        ) {
          this.dispatch("getpost", parameter).then((res) => {
            var list = res["list"];
            list.forEach((v) => {
              v["iconName"] = "http://gameweb.metaversement.io/" + v["image"];
              v["gameId"] = v["gameid"];
              v["gameType"] = v["gametype"];
              v["gameKind"] = v["gamekind"];
              v["gameName"] = v["gamename"];
            });
            localStorage.setItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist",
              JSON.stringify(list)
            );
            context.commit("GetList", list);
          });
        } else {
          list = JSON.parse(
            localStorage.getItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
            )
          );
          list.forEach((v) => {
            v["iconName"] = "http://gameweb.metaversement.io/" + v["image"];
            v["gameId"] = v["gameid"];
            v["gameType"] = v["gametype"];
            v["gameKind"] = v["gamekind"];
            v["gameName"] = v["gamename"];
          });
          context.commit("GetList", list);
        }
      } else {
        if (
          localStorage.getItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
          ) == null
        ) {
          var list = [];
          list = dist2.filter((v) => {
            if (parameter.body.gametype === "all") {
              return v;
            } else {
              return (
                String(v["Gamestype"]).toLowerCase() == parameter.body.gametype
              );
            }
          });
          localStorage.setItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist",
            JSON.stringify(list)
          );
          context.commit("GetList", list);
        } else {
          list = JSON.parse(
            localStorage.getItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
            )
          );
          context.commit("GetList", list);
        }
      }
    },
  },
  getters: {
    pop: (state) => {
      return state.pop;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    list: (state) => {
      return state.list;
    },
    flag_nav: (state) => {
      return state.flag_nav;
    },
    all_game: (state) => {
      return state.all_game;
    },
  },
});
