<template>
  <div>
    <gamecateview />
    <game_page :m_show_id="'main_slot'" />
  </div>
</template>

<script>
import game_page from "./game_page.vue";
import gamecateview from "./game_category.vue";
export default {
  name: "main",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
  components: {
    game_page,
    gamecateview,
  },
};
</script>
