<template>
  <!-- 最上面的nav -->
  <div class="header-wrapper-m">
    <div
      class="content_div fixed-header gb-logged-out pb-0"
      style="
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2),
          0 4px 6px -2px rgba(0, 0, 0, 0.1);
        background-color: #12151e;
      "
    >
      <nav
        class="navigation-menu-wrapper d-flex align-items-center position-relative mx-0 my-0"
        style="width: 100%"
      >
        <div
          class="logo-wrapper-div"
          style="
            position: relative;
            display: grid;
            width: 100%;
            align-items: center;
            grid-template-columns: auto 1fr;
            gap: 8px;
          "
        >
          <img
            src="@/assets/img/main/ic1-1.png"
            alt=""
            class="top-left-nav d-xl-none d-block"
            @click="hide_nav()"
          />
          <div class="ms-3 logo-wrapper-m lcfc">
            <img
              src="@/assets/logo.png"
              class="top_head_img c-pointer d-block"
              @click="this.$router.push('/')"
            />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "maintop2",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters(["flag_nav"]),
  },
  methods: {
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
  },
  created() {},
  watch: {},
};
</script>
