<template>
  <div class="mt-3">
    <div v-if="show_id == 'slot'">
      <div class="home_game_arrow home_game_slot_sub g-flex-wrap">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <img
              src="@/assets/img/game_category/icon-g-slot.png"
              alt=""
              style="width: 28px; height: 28px"
            />
            <span class="text_span"> {{ $t("home_text7").toLowerCase() }}</span>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="false"
        >
          <swiper-slide
            class="home_game_img home_game_img_hot d-flex"
            v-for="(l, k) in c_s_list('slot')"
            :key="k"
          >
            <div
              class="home_game_img_div w-100 c-pointer"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
            >
              <div :class="css_list" class="game_item">
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("text_free_play_game") }}</span>
                </div>
              </div>

              <div class="mt-2">
                <p
                  style="
                    word-break: break-all;
                    white-space: pre-line;
                    height: 45px;
                    overflow-y: hidden;
                  "
                >
                  {{ l.gamename }}
                </p>
                <p
                  :class="[
                    random_arr(0, k) < 98 ? 'bg-new-green' : 'bg-new-red',
                  ]"
                  class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
                >
                  <span>{{ random_arr(0, k) }}</span> %
                </p>
                <div
                  class="d-flex justify-content-center align-items-center text-center"
                >
                  <span
                    class="ms-2 fs-375-14 fw-bolder"
                    style="color: #60f360"
                    >{{ $t("text_free_play_game") }}</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      v-if="show_id == 'main_slot'"
      class="row home_game_arrow home_game_slot_sub g-flex-wrap"
    >
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
        :allowTouchMove="false"
      >
        <swiper-slide
          class="home_game_img home_game_img_hot d-flex"
          v-for="(l, k) in c_s_list('migame')"
          :key="k"
        >
          <div
            class="home_game_img_div w-100 c-pointer"
            @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
          >
            <div :class="css_list" class="game_item">
              <img v-lazy="l.iconName" class="w-100" />
              <div class="game-actions">
                <span class="btn-play">{{ $t("text_free_play_game") }}</span>
              </div>
            </div>

            <div class="mt-2">
              <p
                style="
                  word-break: break-all;
                  white-space: pre-line;
                  height: 45px;
                  overflow-y: hidden;
                "
              >
                {{ l.gamename }}
              </p>
              <p
                :class="[random_arr(1, k) < 98 ? 'bg-new-green' : 'bg-new-red']"
                class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
              >
                <span>{{ random_arr(1, k) }}</span> %
              </p>
              <div
                class="d-flex justify-content-center align-items-center text-center"
              >
                <span class="ms-2 fs-375-14 fw-bolder" style="color: #60f360">{{
                  $t("text_free_play_game")
                }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      v-if="show_id == 'fish'"
      class="home_game_arrow home_game_casino home_game_fish g-flex-wrap"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-fish.png"
            alt=""
            style="height: 30px; width: 30px"
          />
          <span class="text_span"> {{ $t("text_main12").toLowerCase() }}</span>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
        :allowTouchMove="false"
      >
        <swiper-slide
          class="home_game_img home_game_img_hot d-flex"
          v-for="(l, k) in c_s_list('fish')"
          :key="k"
        >
          <div
            class="home_game_img_div w-100 c-pointer"
            @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
          >
            <div :class="css_list" class="game_item">
              <img v-lazy="l.iconName" class="w-100" />
              <div class="game-actions">
                <span class="btn-play">{{ $t("text_free_play_game") }}</span>
              </div>
            </div>

            <div class="mt-2">
              <p
                style="
                  word-break: break-all;
                  white-space: pre-line;
                  height: 45px;
                  overflow-y: hidden;
                "
              >
                {{ l.gamename }}
              </p>
              <p
                :class="[random_arr(2, k) < 98 ? 'bg-new-green' : 'bg-new-red']"
                class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
              >
                <span>{{ random_arr(2, k) }}</span> %
              </p>
              <div
                class="d-flex justify-content-center align-items-center text-center"
              >
                <span class="ms-2 fs-375-14 fw-bolder" style="color: #60f360">{{
                  $t("text_free_play_game")
                }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="show_id == 'hot'">
      <div class="home_game_arrow home_game_casino home_game_hot g-flex-wrap">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <img
              src="@/assets/img/game_category/ic2_hot.png"
              alt=""
              style="width: 28px; height: 28px"
            />
            <span class="text_span">
              {{ $t("text_main11").toLowerCase() }}</span
            >
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="false"
        >
          <swiper-slide
            class="home_game_img home_game_img_hot d-flex"
            v-for="(l, k) in c_s_list('hot')"
            :key="k"
          >
            <div
              class="home_game_img_div w-100 c-pointer"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
            >
              <div :class="css_list" class="game_item">
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("text_free_play_game") }}</span>
                </div>
              </div>

              <div class="mt-2">
                <p
                  style="
                    word-break: break-all;
                    white-space: pre-line;
                    height: 45px;
                    overflow-y: hidden;
                  "
                >
                  {{ l.gamename }}
                </p>
                <p
                  :class="[
                    random_arr(3, k) < 98 ? 'bg-new-green' : 'bg-new-red',
                  ]"
                  class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
                >
                  <span>{{ random_arr(3, k) }}</span> %
                </p>
                <div
                  class="d-flex justify-content-center align-items-center text-center"
                >
                  <span
                    class="ms-2 fs-375-14 fw-bolder"
                    style="color: #60f360"
                    >{{ $t("text_free_play_game") }}</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { mapActions, mapGetters } from "vuex";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);

export default {
  props: { show_id: String, allinslot: Array },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      css_list: "position-relative",
      random_list: [],
    };
  },
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      var action_val = t != "allcasino" && t != "sport" ? "GameLobby" : "";

      var gametype =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[0]
          : t.toUpperCase() == "JDBCHESS"
          ? "JDB"
          : t;
      var type =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[1]
          : t.toUpperCase() == "JDBCHESS"
          ? "Card"
          : "all";

      let param = {
        action: action_val,
        body: {
          gametype: gametype,
          type: type,
          uid: localStorage.getItem("uid"),
        },
      };
      this.getGameList(param);
    },
    set_gametype: function (e) {
      this.$router.push("/slot/slot/" + e);
    },
    game: function (device, mode, id, type, kind) {
      this.$parent.game(device, mode, id, type, kind);
    },
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    change_page() {
      this.all_game["slot"].forEach((v) => {
        this.gamelist(v);
      });
    },
    random_num() {
      return Math.ceil(Math.random() * 99849 + 150);
    },
    set_random(count, layer) {
      var arr = [];
      for (var i = 0; i < layer; i++) {
        var o = [];
        for (var j = 0; j < count; j++) {
          var aa = (Math.random() * (115 - 95) + 95).toFixed(2);
          o.push(aa);
        }
        arr.push(o);
      }
      this.random_list = arr;
    },
  },
  watch: {},
  computed: {
    ...mapGetters(["all_game", "list"]),
    sub_type() {
      if (this.$route.params.sub_type != null)
        return this.$route.params.sub_type;
      else return "";
    },
    slot_list() {
      if (this.sub_type != null && this.sub_type != "")
        return [this.sub_type.toLowerCase()];
      else return this.all_game["slot"];
    },
    c_s_list() {
      return function (id) {
        this.list;
        if (
          JSON.parse(localStorage.getItem("migame_all_gamelist")) != null &&
          JSON.parse(localStorage.getItem("migame_all_gamelist")) != ""
        ) {
          if (id == "fish") {
            return JSON.parse(
              localStorage.getItem("migame_all_gamelist")
            ).filter((v) => {
              if (
                v.mode == id &&
                v.gameId != "210903" &&
                v.gameId != "221101" &&
                v.gameId != "210401" &&
                v.gameId != "210907" &&
                v.gameId != "210706" &&
                v.gameId != "TJ-SSTJ-9" &&
                v.gameId != "WFP-LITTLEMARIO-9"
              ) {
                return v;
              }
            });
          } else if (id == "slot") {
            return JSON.parse(
              localStorage.getItem("migame_all_gamelist")
            ).filter((v) => {
              if (
                v.mode == id &&
                v.gameId != "210903" &&
                v.gameId != "221101" &&
                v.gameId != "210401" &&
                v.gameId != "210907" &&
                v.gameId != "210706" &&
                v.gameId != "TJ-SSTJ-9" &&
                v.gameId != "WFP-LITTLEMARIO-9"
              ) {
                return v;
              }
            });
          } else
            return JSON.parse(
              localStorage.getItem("migame_all_gamelist")
            ).filter((v) => {
              if (
                v.gameId != "210903" &&
                v.gameId != "221101" &&
                v.gameId != "210401" &&
                v.gameId != "210907" &&
                v.gameId != "210706" &&
                v.gameId != "TJ-SSTJ-9" &&
                v.gameId != "WFP-LITTLEMARIO-9"
              ) {
                return v;
              }
            });
        }
      };
    },
    pp_list() {
      return function (jj) {
        this.list;
        if (this.sub_type != null && this.sub_type != "") {
          if (jj.toUpperCase().indexOf("A1-") != -1) {
            return JSON.parse(
              localStorage.getItem(
                "a1_" + jj.split("-")[1].toLowerCase() + "_gamelist"
              )
            );
          } else
            return JSON.parse(
              localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
            );
        } else {
          try {
            if (this.list.length <= 0) {
              return [];
            } else {
              if (jj.indexOf("A1-") != -1) {
                return JSON.parse(
                  localStorage.getItem(
                    "a1_" + jj.split("-")[1].toLowerCase() + "_gamelist"
                  )
                ).slice(0, 15);
              } else
                return JSON.parse(
                  localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
                ).slice(0, 15);
            }
          } catch {
            return [];
          }
        }
      };
    },
    filterSearch() {
      return this.all_game["slot"];
    },
    random_arr() {
      var new_arr_2 = this.random_list;
      return function (id, index) {
        if (new_arr_2 != null && new_arr_2 != "") {
          return new_arr_2[id].slice(index, index + 1)[0];
        }
      };
    },
  },
  mounted() {},
  created() {
    this.set_random(200, 10);
    this.change_page();
  },
};
</script>
