<template>
  <div>
    <Loading :active="isLoading" :can-cancel="true">
      <img
        src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
        class="w-100"
        style="z-index: 9999"
      />
    </Loading>
    <router-view />
    <pop></pop>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import pop from "./views/Home/pop.vue";

export default {
  created() {},
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  components: {
    Loading,
    pop,
  },
  methods: {},
};
</script>
