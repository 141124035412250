<template>
  <div>
    <div class="d-flex w-100 h-100 overflow-hidden">
      <maintop1 />
      <div class="d-flex w-100 h-100 overflow-hidden flex-column" id="home">
        <maintop2 ref="getbalance_home" />
        <div
          class="d-flex overflow-x-hidden overflow-y-auto"
          style="height: 100vh; background-color: #213040"
          id="content_page"
        >
          <div style="width: 200px" class="flag_width_200"></div>
          <div class="mb-5 content_page m-0 mx-auto right_ann_close">
            <div class="h-100">
              <div
                class="d-flex justify-content-between flex-column align-items-center h-100"
              >
                <div class="content_div">
                  <div style="width: 100%">
                    <mainview v-if="Id == ''" />
                    <game_page v-if="Id == 'slot'" />
                  </div>
                </div>
                <footertwo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import mainview from "./main.vue";
import footertwo from "./Home/footer_two.vue";
import game_page from "./game_page.vue";
export default {
  name: "Home",
  data() {
    return {
      isMobile: this.$Utils.isMobile(),
    };
  },
  components: {
    maintop1,
    maintop2,
    mainview,
    footertwo,
    game_page,
  },
  computed: {
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {},
  created() {},
  watch: {},
  mounted() {},
};
</script>
