import { createI18n } from "vue-i18n";
import cn from "./cn.json";

var locale = localStorage.getItem("locale") ?? process.env.VUE_APP_LANG;

locale = "cn";
sessionStorage.setItem("Json", JSON.stringify(locale));
localStorage.setItem("locale", locale);

const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    cn: cn,
  },
});

export default i18n;
