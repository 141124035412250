import { get2, post } from "./request";

/**
 * 取得客戶ＩＰ
 */

const getIP = () => {
  return get2("/getip.aspx", "");
};
/**
 * Post API
 */
const apiPost = async (action, dist) => {
  var res = await post(apiurl(action), xmlfile(action, dist));
  var data = formart(res);
  return new Promise((resolve) => {
    resolve(data);
  });
};

const xmlfile = (action, data) => {
  var xml = "";
  if (action == "rebate1") {
    action = "rebate";
  }
  xml =
    "<?xml version='1.0' encoding='UTF-8'?><request action='" +
    action +
    "'><element>";
  for (var key in data) {
    xml += "<properties name='" + key + "'>" + data[key] + "</properties>";
  }
  const lang = localStorage.getItem("locale") || process.env.VUE_APP_LANG;

  xml += "<properties name='lang'>" + lang + "</properties>";

  xml +=
    "<properties name='station'>" +
    process.env.VUE_APP_STATOIN +
    "</properties>";
  xml += "</element></request>";
  return xml;
};

const formart = (data) => {
  try {
    var str = data.replace("\r\n\r\n", "").split("<!")[0];
    return str;
  } catch {
    return data;
  }
};

const apiurl = (action) => {
  var url;
  switch (action) {
    default:
      url = "/APP_api/" + action + "_APP_api.aspx";
      break;
  }
  return url;
};

export default {
  getIP,
  apiPost,
};
