<template>
  <div v-if="pop.isLoading"></div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return { balance: "0.00" };
  },
  computed: {
    ...mapGetters(["pop"]),
  },
  methods: {
    comfirm_fun: function (e) {
      switch (e) {
        case "home":
          this.$router.push("/");
          break;
        default:
          break;
      }

      this.$store.commit("Pop", { isLoading: false });
    },
    show_alert: function () {
      this.$swal({
        html: this.pop.data.msg,
        icon: this.pop.data.action,
        showCancelButton: false,
        confirmButtonText: this.$t("shared_comfirm"),
        cancelButtonText: this.$t("shared_cancel"),
      }).then(() => {
        this.comfirm_fun(this.pop.data.type);
      });
    },
  },
  watch: {
    pop: function () {
      if (this.pop.isLoading) this.show_alert();
    },
  },
  created() {},
};
</script>
