<template>
  <div class="footer w-100"></div>
</template>

<script>
export default {
  name: "footertwo",
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
};
</script>
