<template>
  <!-- 侧边的nav -->
  <div>
    <div class="prewindow_div" @click="hide_nav()" :class="pre_top1_div"></div>
    <div
      class="collapse navbar-collapse"
      id="navbarNav"
      :class="flag_nav ? 'show' : ''"
    >
      <div class="icon-wrapper" @click="hide_nav()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#8d999f"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </div>

      <div class="left-new-logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>

      <div class="d-flex flex-column w-100 navbar-div-nav" id="scro_left">
        <ul class="navbar-nav flex-column mb-5">
          <li @click="this.$router.push('/slot/hot'), false_nav()">
            <img
              src="@/assets/img/game_category/ic2_hot.png"
              :title="$t('text_main11_1')"
            />
            <a class="nav-link fw-bold">{{ $t("text_main11_1") }}</a>
          </li>
          <li @click="this.$router.push('/slot/slot'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-slot.png"
              :title="$t('home_text7_1')"
            />
            <a class="nav-link fw-bold">{{ $t("home_text7_1") }}</a>
          </li>
          <li @click="this.$router.push('/slot/fish'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-fish.png"
              :title="$t('text_main12_1')"
            />
            <a class="nav-link fw-bold">{{ $t("text_main12_1") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "maintop1",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters(["flag_nav"]),
    pre_top1_div: function () {
      if (window.innerWidth <= 1199 && this.flag_nav) {
        return "d-block";
      } else return "d-none";
    },
  },
  methods: {
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    false_nav: function () {
      if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
      else this.$store.commit("flagnav", this.flag_nav);
    },
  },
  created() {
    if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
  },
  watch: {},
};
</script>
